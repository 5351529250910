/*
 * To give the impression of infinite looping slides two extra slides are added:
 * one in the first and one in the last position.
 *
 * See https://stackoverflow.com/questions/15876754/infinity-loop-slider-concepts
 */

define(['throttle', 'app', 'swipeListener', 'siteObj'], function(Throttle, app, SwipeListener, siteObj) {

  //// See https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Safely_detecting_option_support
  let supportsCaptureOption = false;
  function setHasSupportToCaptureOption(hasSupport) {
    supportsCaptureOption = hasSupport;
  }

  try {
    addEventListener('test', null, Object.defineProperty({}, 'capture', {get: function () {
      setHasSupportToCaptureOption(true);
    }}));
  } catch(e) {/**/}

  function getSafeEventHandlerOpts(options = { capture: true }) {
    return supportsCaptureOption ? options : options.capture;
  }
  ////

  // This default value will need to be updated in the test if changed
  let slideFrequency = 5000;
  const classNamePrefix = 'responsiveSlider';
  const classNames = {
    jsLoaded: `${classNamePrefix}-jsLoaded`,
    jsSetHeights: `${classNamePrefix}-jsSetHeights`,
    previousButton: `${classNamePrefix}_previousButton`,
    nextButton: `${classNamePrefix}_nextButton`,
    slideContainer: `${classNamePrefix}_slideContainer`,
    sliderView: `${classNamePrefix}_sliderView`,
    sliderViewSmooth: `${classNamePrefix}_sliderView-smooth`,
    navBullet: `${classNamePrefix}_navBullet`,
    navBulletActive: `${classNamePrefix}_navBullet-active`
  };

  const defaultState = {
    slideIndex: 1,
    isSmoothTransform: true,
    isKeyboardNav: false,
    deltaX: 0,
  };

  const slideAction = nextState => Object.assign({}, defaultState, nextState);

  class ResponsiveSlider {

    constructor() {
      this.state = slideAction(defaultState);
      this.init = this.init.bind(this);
      this.setState = this.setState.bind(this);

      this.handleClick = this.handleClick.bind(this);
      this.slide = this.slide.bind(this);
      this.previous = this.previous.bind(this);
      this.next = this.next.bind(this);
      this.drag = this.drag.bind(this);
      this.removeAnyDragOffset = this.removeAnyDragOffset.bind(this);
      this.getScrollTo = this.getScrollTo.bind(this);
      this.startTimer = this.startTimer.bind(this);
      this.stopTimer = this.stopTimer.bind(this);
      this.handleSlideTransitionEnd = this.handleSlideTransitionEnd.bind(this);
      this.setHeight = this.setHeight.bind(this);
      this.setActive = this.setActive.bind(this);
      this.handleArrowNavigation = this.handleArrowNavigation.bind(this);
      this.focusOnActive = this.focusOnActive.bind(this);
      this.setSlideFrequency = this.setSlideFrequency.bind(this);
    }

    init(element) {
      this.element = element;
      this.isAutoSlideOn = element.getAttribute('data-isAutoSlideOn') !== 'false';
      this.isHeightVariable = element.getAttribute('data-isHeightVariable') !== 'false';
      this.setSlideFrequency();

      const prev = element.getElementsByClassName(classNames.previousButton)[0];
      prev.addEventListener('click', () => { this.previous() });

      const next = element.getElementsByClassName(classNames.nextButton)[0];
      next.addEventListener('click', () => { this.next() });

      this.sliderView = element.getElementsByClassName(classNames.sliderView)[0];
      this.sliderView.addEventListener('transitionend', this.handleSlideTransitionEnd);
      this.sliderView.addEventListener('click', this.handleClick, getSafeEventHandlerOpts({
        capture: true,
        passive: false
      }));

      this.slideContainers = Array.from(element.getElementsByClassName(classNames.slideContainer));
      this.slideCount = this.slideContainers.length;
      if (this.slideCount > 0) {
        this.slideContainers[0].setAttribute('aria-hidden', 'true');
        this.slideContainers[this.slideCount - 1].setAttribute('aria-hidden', 'true');
      }

      this.bulletElements = Array.from(element.getElementsByClassName(classNames.navBullet));
      this.bulletElements.forEach((bulletElement, index) => {
        bulletElement.addEventListener('click', this.getScrollTo(index + 1));

        if((index === 0 && this.state.slideIndex === this.slideCount -1)
        || (index + 1 === this.state.slideIndex) 
        || (index + 1 === this.bulletElements.length && this.state.slideIndex === 0)) {
          bulletElement.innerHTML = `<span class="visually-hidden">${siteObj.props.cardScroller.currentSlide1} ${index+1} ${siteObj.props.cardScroller.currentSlide2}</span>`;
        } else {
          bulletElement.innerHTML = `<span class="visually-hidden">${siteObj.props.cardScroller.slide} ${index+1}</span>`;
        }
      });

      this.startTimer();

      new SwipeListener({
        target: this.sliderView,
        onSwipeStart: () => {this.preventClick = false; this.stopTimer();},
        onSwipeMove: this.drag,
        onSwipeRight: () => {this.preventClick = true; this.previous();},
        onSwipeLeft: () => {this.preventClick = true; this.next();},
        onSwipeUp: () => {this.preventClick = true},
        onSwipeDown: () => {this.preventClick = true},
        onSwipeEnd: () => {this.removeAnyDragOffset(); this.startTimer();},
        allowMouseEvents: true,
        tolerance: 30
      });

      this.element.addEventListener('mouseover', () => { this.stopTimer() });
      this.element.addEventListener('mouseout', () => { this.startTimer() });
      this.element.addEventListener('focusin', () => { this.stopTimer() });
      this.element.addEventListener('focusout', () => { this.startTimer() });

      this.element.addEventListener('keyup', event => { this.handleArrowNavigation(event.key) });

      this.setActive();

      const throttle = new Throttle(this.setHeight, 200);
      window.addEventListener('resize', throttle.run);
      app.element.addClass(classNames.jsLoaded, this.element);
    }

    setState(nextState) {
      this.state = nextState;
      this.slide();
    }

    stopTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    }

    setSlideFrequency() {
      const slideDuration = Number(this.element.getAttribute('data-slideDuration'));

      return slideFrequency = (slideDuration != "") ? slideDuration : slideFrequency;
    }

    startTimer() {

      if (this.isAutoSlideOn && !window.navigator.userAgent.includes('HeadlessChrome')) {
        this.stopTimer();
        this.intervalId = setInterval(this.next, slideFrequency);
      }
    }

    handleClick(event) {
      if (this.preventClick) {
        this.preventClick = false;
        event.preventDefault();
      }
    }

    drag(delta, event) {
      this.setState(slideAction({ slideIndex: this.state.slideIndex, deltaX: delta.x }));
      return event.type !== 'touchmove';
    }

    removeAnyDragOffset() {
      this.setState(slideAction({ slideIndex: this.state.slideIndex }));
    }

    getScrollTo(slideIndex) {
      return () => {
        this.startTimer();
        this.setState(slideAction({ slideIndex }));
      };
    }

    previous(isKeyboardNav = false) {
      this.startTimer();
      if (this.state.slideIndex === 0) {
        this.setState(slideAction({ slideIndex: this.slideCount - 2, isKeyboardNav }));
      } else {
        this.setState(slideAction({ slideIndex: this.state.slideIndex - 1, isKeyboardNav }))
      }
    }

    next(isKeyboardNav = false) {
      this.startTimer();
      if (this.state.slideIndex === this.slideCount - 1) {
        this.setState(slideAction({ slideIndex: 1, isKeyboardNav }));
      } else {
        this.setState(slideAction({ slideIndex: this.state.slideIndex + 1, isKeyboardNav }));
      }
    }

    handleSlideTransitionEnd(event) {
      // the only type of transition we need to handle is transform or these will be side effects.
      // e.g. if the user tabs and the focus is set on a button/link inside a primary banner
      // a background-color transition might be triggered.
      if (event.propertyName === 'transform') {
        if (this.state.slideIndex === 0) {
          this.setState(slideAction({
            slideIndex: this.slideCount - 2,
            isSmoothTransform: false,
            isKeyboardNav: this.state.isKeyboardNav,
          }));
        } else if (this.state.slideIndex === this.slideCount - 1) {
          this.setState(slideAction({
            slideIndex: 1,
            isSmoothTransform: false,
            isKeyboardNav: this.state.isKeyboardNav,
          }));
        }
        if (this.state.isKeyboardNav && this.state.slideIndex !== 0 && this.state.slideIndex !== this.slideCount - 1) {
          this.focusOnActive();
        }
      }
    }

    setHeight() {
      app.element.addClass(classNames.jsSetHeights, this.element);
      if (this.isHeightVariable) {
        const currentSlideContainer = this.slideContainers[this.state.slideIndex];
        const elementToMeasure = currentSlideContainer.firstElementChild;
        if (elementToMeasure) {
          const elementStyles = window.getComputedStyle(elementToMeasure);
          const height = elementToMeasure.offsetHeight
            + parseInt(elementStyles.getPropertyValue('margin-top'))
            + parseInt(elementStyles.getPropertyValue('margin-bottom'));
          this.sliderView.style.maxHeight = `${height}px`;
        }
      }
    }

    slide() {
      this.bulletElements.forEach((bulletElement, index) => {
        if ((index === 0 && this.state.slideIndex === this.slideCount -1)
          || (index + 1 === this.state.slideIndex)
          || (index + 1 === this.bulletElements.length && this.state.slideIndex === 0)
        ) {
          app.element.addClass(classNames.navBulletActive, bulletElement);
          bulletElement.innerHTML = `<span class="visually-hidden">${siteObj.props.cardScroller.currentSlide1} ${index+1} ${siteObj.props.cardScroller.currentSlide2}</span>`;
        } else {
          app.element.removeClass(classNames.navBulletActive, bulletElement);
          bulletElement.innerHTML = `<span class="visually-hidden">${siteObj.props.cardScroller.slide} ${index+1}</span>`;
        }
      });

      if (this.state.isSmoothTransform) {
        app.element.addClass(classNames.sliderViewSmooth, this.sliderView);
      } else {
        app.element.removeClass(classNames.sliderViewSmooth, this.sliderView);
      }

      const currentSlideContainer = this.slideContainers[this.state.slideIndex];
      const elementToMeasure = currentSlideContainer.firstElementChild;
      let dragOffsetPercent = 0;

      if (elementToMeasure && this.state.deltaX !== 0) {
        const elementStyles = window.getComputedStyle(elementToMeasure);
        const width = elementToMeasure.offsetWidth
          + parseInt(elementStyles.getPropertyValue('margin-left'))
          + parseInt(elementStyles.getPropertyValue('margin-right'));
        dragOffsetPercent = (this.state.deltaX / width) * 100;
      }

      if (document.getElementsByTagName('html')[0].getAttribute('dir') === 'ltr') {
        this.sliderView.style.transform = `translateX(-${(100 * this.state.slideIndex) - dragOffsetPercent}%)`;
      } else {
        this.sliderView.style.transform = `translateX(${(100 * this.state.slideIndex) - dragOffsetPercent}%)`;
      }

      this.setHeight();
      this.setActive();
    }

    /*
      Accessibility change. Make only the slide in view tabbable/focusable by setting every link
      to 'tabindex="-1"' except for the ones inside this slide.
     */
    setActive() {
      this.element.querySelectorAll('a').forEach(link => { link.setAttribute('tabindex', '-1') });
      const currentSlideContainer = this.slideContainers[this.state.slideIndex];
      currentSlideContainer.querySelectorAll('a').forEach(link => { link.removeAttribute('tabindex') });
    }

    focusOnActive() {
      const currentSlideContainer = this.slideContainers[this.state.slideIndex];
      const tabbableEle = currentSlideContainer.querySelector('a');
      tabbableEle && tabbableEle.focus();
    }

    handleArrowNavigation(eventKey) {
      if (eventKey === 'ArrowLeft' || eventKey === 'Left') {
        this.previous(true);
      } else if (eventKey === 'ArrowRight' || eventKey === 'Right') {
        this.next(true);
      }
    }

  }
  return ResponsiveSlider;
});
